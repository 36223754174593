<template>
	<GridInstagramFeed
		:key="renderKey"
		:media="media"
		@dragstart.stop.prevent
	/>
</template>

<script>
import {
	getCurrentInstance,
	watch,
	computed,
	ref,
} from '@vue/composition-api';

import {
	deleteToken,
	getToken,
} from '@/api/InstagramApi';
import { isDesktopSafari } from '@/utils/browserIdentifiers';
import GridInstagramFeed from '@user/components/grid-components/instagramFeed/GridInstagramFeed.vue';
import { useGridInstagramFeed } from '@user/components/grid-components/instagramFeed/useGridInstagramFeed';

export default {
	components: { GridInstagramFeed },
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props, context) {
		const { commit } = getCurrentInstance().$store;
		const username = computed(() => props.data.settings.username);

		const cleanupUser = (token) => {
			// If there is token in DB, remove it
			if (token) {
				deleteToken(props.id);
			}

			// Clear username (used as isInstagramConnected)
			if (username.value) {
				commit('setElementData', {
					elementId: props.id,
					data: { settings: { username: null } },
				});
			}
		};

		const {
			media,
			init,
			itemGap,
		} = useGridInstagramFeed(props, context, {
			// TODO: replace it with URL instead of callback
			getInstagramTokenHandler: getToken,
			cleanupCallback: cleanupUser,
		});

		watch(username, init, { immediate: true });

		const renderKey = ref(0);

		watch(itemGap, () => {
			if (isDesktopSafari) {
				// Rerender on each change in safari or else it wont rerender changed gaps
				renderKey.value += 1;
			}
		});

		return {
			media,
			itemGap,
			renderKey,
		};
	},
};
</script>
